<template>
  <div>
    <div class="container-fluid">
      <div class="row text-center">
        <div class="w-100 d-flex justify-center" style="margin-top: 5%">
          <my-alert :show="!errorMessageShow" variant="warning">
            {{ $t('loginRegister.activationProcMsg') }}
          </my-alert>
          <my-alert
            :show="dismissCountDown"
            :dismissible="true"
            variant="danger"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            <p>
              {{ this.errorMessage }}
              {{ $t('loginRegister.redirectMsg2') }}
              {{ dismissCountDown }}
              {{ $t('loginRegister.second') }}
            </p>
            <v-progress-linear
              :value="(dismissCountDown / dismissSecs) * 100"
              color="warning"
              height="4"
            ></v-progress-linear>
          </my-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultHeadMixin from '@/mixins/defaultHead';
import MyAlert from '@/components/utils/my-alert.vue';

export default {
  name: 'token',
  components: { MyAlert },
  mixins: [DefaultHeadMixin],
  layout: 'default',
  data: () => ({
    errorMessage: '',
    errorMessageShow: false,
    dismissSecs: 10,
    dismissCountDown: 0,
  }),
  mounted() {
    this.accountActivation();
  },
  methods: {
    async accountActivation() {
      try {
        console.log('kwkwkkw', this.$route);
        let response = await this.$store.dispatch(
          'global/confirmPartnerMembership',
          this.$route.query.invite_member_partner_token,
        );
        console.log('response activation', response);
        if (response.data.new_token) {
          await this.$store.dispatch('global/updateToken', response.data.new_token);
          await this.$store.dispatch('global/fetch');
        }
        await this.$swal(
          this.$i18n.t('confirmPartnerMembership.successTitle'),
          this.$i18n.t('confirmPartnerMembership.message'),
          'success',
        );
        this.$router.replace('/');
      } catch (e) {
        this.errorMessageShow = true;
        this.dismissCountDown = 30;
        this.errorMessage = e.response.data.errors;
        setTimeout(() => {
          this.$router.replace('/');
        }, (this.dismissCountDown + 1) * 1000);
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
};
</script>

<style scoped></style>
